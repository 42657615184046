import React from "react";
import Head from "next/head";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";

import AppBar from "@Components/appBar/appBar";
import Footer from "@Components/common/footer";
import Hero from "@Components/index/hero";

function Error() {
  return (
    <React.Fragment>
      <Head>
        <title>{`ときやど | 404エラー`}</title>
      </Head>

      <AppBar noMargin={true}>
        <Hero
          title={`404エラー`}
          desc="申し訳ございません。お探しのページは見つかりませんでした。"
          button={"トップページ"}
          link="/"
          icon={<HomeOutlinedIcon />}
        />
        <Footer />
      </AppBar>
    </React.Fragment>
  );
}

export default Error;
